/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect } from "react";
import SearchBarContainer from "./search/InventoryView.Details.SearchBarContainer";
import SearchFiltersContainer from "./search/InventoryView.Details.FilterSectionContainer";
import { useTranslation } from "react-i18next";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { useColumns } from "./search/InvertoryView.Details.Search.Columns";
import { Search } from "components/templates/Search.template";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

import { FontSize, Icon } from "components/atoms/Icon.atom";
import { faChartLine } from "@fortawesome/pro-solid-svg-icons";
import { faHouse } from "@fortawesome/pro-regular-svg-icons";
import { ResponsiveSection } from "styles/container-elements";
import Colors from "styles/colors";
import { Text } from "components/atoms/Text.atom";
import { Button } from "react-bootstrap";
import _ from "lodash";

const baseTab = {
  border: 0,
  borderRadius: "4px 4px 0 0",
  fontSize: 20,
  marginRight: "5px",
  padding: "15px 20px",
  borderBottom: "4px",
  borderStyle: "solid",
  opacity: 1,
};

const selectedTab = {
  ...baseTab,
  color: Colors.inventoryView.BLUE,
  borderBlockColor: Colors.inventoryView.BLUE,
  ":disabled": {
    opacity: 1,
    color: Colors.inventoryView.BLUE,
    backgroundColor: Colors.inventoryView.WHITE,
    borderBlockColor: Colors.inventoryView.BLUE,
  },
};

const defaultTab = {
  ...baseTab,
  color: Colors.inventoryView.DARK_GRAY,
  borderBlockColor: Colors.inventoryView.LIGHT_GRAY,
  opacity: 0.6,
  ":hover": {
    ...selectedTab,
    backgroundColor: Colors.inventoryView.WHITE,
    borderBlockColor: Colors.inventoryView.BLUE,
  },
};

export const InventoryViewDetails = ({
  fetchLocation,
  fetchVinSearch,
  solutionId,
  locationId,
  location,
  page,
  pageSize,
  setDetailsSearchPagination,
  searchResults,
  onsiteVinsResetSearch,
  totalCount,
  showFilters,
  toggleShowFilters,
  sortColumn,
  reverseSort,
  setDetailsSearchSort,
  isLoading,
  resetSearchAndFilters,
  pushDashboardView,
}) => {
  const { t } = useTranslation("inventory-view");

  useSetTitleOnMount(t("inventory-view:InventoryView Details"));

  useTrackWithMixpanelOnce("Viewed Page: InventoryView / Details");

  useEffect(() => {
    resetSearchAndFilters(true);
    fetchLocation(solutionId, locationId);
    fetchVinSearch(solutionId, locationId);
  }, [
    solutionId,
    locationId,
    fetchLocation,
    resetSearchAndFilters,
    fetchVinSearch,
  ]);

  const columns = useColumns();

  if (!location) {
    return null;
  }

  const totalPages = _.ceil(totalCount / pageSize);

  return (
    <ResponsiveSection
      style={{
        justifyContent: "flex-start",
        background: Colors.background.LIGHT_GRAY,
        overflowY: "auto",
        height: "100%",
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          padding: "1em 2em 1em 2em",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "1em",
            borderBottom: "1px solid",
            borderColor: Colors.tabs.BACKGROUND,
            alignItems: "center",
            minHeight: "65px",
          }}
        >
          <div>
            <Button
              variant="transparent"
              css={{ ...defaultTab }}
              onClick={() => pushDashboardView()}
            >
              <Icon src={faHouse} />{" "}
              <Text
                css={{
                  fontSize: "inherit",
                  textUnderlineOffset: "2px",
                  textDecoration: "underline",
                }}
              >
                {t(`inventory-view:Home`)}
              </Text>
            </Button>
            <Button variant="transparent" disabled css={{ ...selectedTab }}>
              <Icon src={faChartLine} />{" "}
              <Text
                css={{
                  fontSize: "inherit",
                  textUnderlineOffset: "2px",
                  textDecoration: "underline",
                }}
              >
                {t(`inventory-view:Details`)}
              </Text>
            </Button>
          </div>
        </div>
        <Text
          size={FontSize.size32}
        >{`${location.name} (${location.code})`}</Text>
      </div>
      <Search
        isLoading={isLoading}
        searchResults={searchResults}
        totalCount={totalCount}
        totalCountTitle={t("inventory-view:Total VINs")}
        resetSearch={() => onsiteVinsResetSearch(solutionId)}
        showFilters={showFilters}
        hideSavedSearch={true}
        toggleShowFilters={toggleShowFilters}
        SearchBarContainer={SearchBarContainer}
        FiltersContainer={SearchFiltersContainer}
        tableProps={{
          data: searchResults,
          columns: columns,
          isLoading: isLoading,
          showPagination: true,
          fixPaginationToBottom: true,
          isManualPagination: true,
          isManualSorting: true,
          sortColumn: sortColumn,
          reverseSort: reverseSort,
          pageIndex: page,
          pageSize: pageSize,
          pageCount: totalPages,
          onPageChange: (newPage) => {
            setDetailsSearchPagination(solutionId, newPage, pageSize);
          },
          onPageSizeChange: (newPageSize) => {
            setDetailsSearchPagination(solutionId, 0, newPageSize);
          },
          onPageSort: (column, isReverse) => {
            setDetailsSearchSort(solutionId, column, isReverse);
          },
          rowClickHandler: null,
        }}
        searchWrapperStyle={{
          backgroundColor: Colors.inventoryView.SEARCH_BAR_GRAY,
          padding: "1em 2em",
        }}
        tableWrapperStyle={{ padding: "0 2em" }}
      />
    </ResponsiveSection>
  );
};

InventoryViewDetails.propTypes = {
  fetchLocation: PropTypes.func.isRequired,
  fetchVinSearch: PropTypes.func,
  location: PropTypes.object,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  solutionId: PropTypes.string,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  setDetailsSearchPagination: PropTypes.func,
  searchResults: PropTypes.array,
  totalCount: PropTypes.number.isRequired,
  showFilters: PropTypes.bool,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  onsiteVinsResetSearch: PropTypes.func.isRequired,
  setDetailsSearchSort: PropTypes.func.isRequired,
  toggleShowFilters: PropTypes.func,
  isLoading: PropTypes.bool,
  resetSearchAndFilters: PropTypes.func,
  pushDashboardView: PropTypes.func,
};

export default InventoryViewDetails;
