import {
  getEverythingQueryString,
  getBasicQueryString,
} from "components/search-bar/search-filter-query-strings";

import { SelectFilterButton } from "components/search-bar/FilterButtons";

export const INVENTORY_VIEW_DETAILS_SEARCH_CATEGORIES = [
  {
    queryKey: "entityId",
    label: () => null,
    placeholder: (t) => t("inventory-view:Search VIN"),
    queryBuilder: getEverythingQueryString,
    property: null,
  },
];

export const INVENTORY_VIEW_DETAILS_FILTERS = [
  {
    queryKey: "isShippable",
    label: (t) => t("inventory-view:Shippability"),
    Component: SelectFilterButton,
    optionsGetter: (props, t) => {
      return [
        {
          label: t("inventory-view:Shippable"),
          value: "true",
        },
        {
          label: t("inventory-view:Non-Shippable"),
          value: "false",
        },
      ];
    },
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString(queryParameter, filterValue),
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
];

export const FORECASTED_VINS_FILTERS = [
  {
    queryKey: "shippability",
    label: (t) => t("inventory-view:Shippability"),
    Component: SelectFilterButton,
    hideSelectEmpty: true,
    hideFuzzySearch: true,
    hideSelectAll: true,
    optionsGetter: (props, t) => {
      return [
        {
          label: t("inventory-view:Shippable"),
          value: "shippable",
        },
        {
          label: t("inventory-view:Non-Shippable"),
          value: "non-shippable",
        },
      ];
    },
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString(queryParameter, filterValue),
  },
];
