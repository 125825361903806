/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-loader";

import {
  useSetTitleOnMount,
  useSetDescriptionOnMount,
} from "components/hooks/useSetTitle";

import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

import Colors from "styles/colors";
import { MdAdd } from "react-icons/md";

import { ResponsiveSection } from "styles/container-elements";
import { Button } from "components/atoms/Button.atom";
import { AddLocationModal } from "./components/AddLocation.modal";

import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { Icon } from "components/atoms/Icon.atom";
import { faHouse } from "@fortawesome/pro-regular-svg-icons";
import { Text } from "components/atoms/Text.atom";

import { selectedTab } from "./components/InventoryNavigationTabs";
import { InventoryLocationPanel } from "./components/InventoryLocationPanel";

const InventoryViewDashboard = ({
  watchedLocations,
  allWatchedLocations,
  pushDetailsView,
  pushDashBoardView,
  fetchWatchedLocations,
  fetchAllWatchedLocations,
  isWatchedLocationsLoading,
  saveWatchedLocations,
}) => {
  const { t } = useTranslation("inventory-view");
  const [showAddLocationModal, setShowAddLocationModal] = useState(false);

  useSetTitleOnMount(t("inventory-view:InventoryView"));

  useSetDescriptionOnMount(
    t(
      "inventory-view:All InventoryView Locations, their current capacity and forecasted capacity",
    ),
  );

  useTrackWithMixpanelOnce("Viewed Page: InventoryView / Dashboard");

  const addLocationModalHideHandler = () => {
    setShowAddLocationModal(false);
  };

  useEffect(() => {
    fetchWatchedLocations();
    fetchAllWatchedLocations();
  }, [fetchWatchedLocations, fetchAllWatchedLocations]);

  return (
    <ResponsiveSection
      style={{
        justifyContent: "flex-start",
        padding: "1em 2em 2em",
        background: Colors.background.LIGHT_GRAY,
        overflowY: "auto",
        height: "100%",
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "1em",
            borderBottom: "1px solid",
            borderColor: Colors.tabs.BACKGROUND,
            alignItems: "center",
            minHeight: "65px",
          }}
        >
          <div>
            <Button
              variant="transparent"
              disabled="selected"
              css={{ ...selectedTab }}
            >
              <Icon src={faHouse} />{" "}
              <Text
                css={{
                  fontSize: "inherit",
                  textUnderlineOffset: "2px",
                  textDecoration: "underline",
                }}
              >
                {t(`inventory-view:Home`)}
              </Text>
            </Button>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <Button
              variant="success"
              onClick={() => {
                setShowAddLocationModal(true);
              }}
            >
              <MdAdd /> {t("inventory-view:Add Location")}
            </Button>
          </div>
          <AddLocationModal
            show={showAddLocationModal}
            onHide={() => {
              addLocationModalHideHandler();
            }}
            watchedLocations={allWatchedLocations}
            saveWatchedLocations={saveWatchedLocations}
            onSave={() => {
              fetchWatchedLocations();
              fetchAllWatchedLocations();
            }}
          ></AddLocationModal>
        </div>

        <Loader loaded={!isWatchedLocationsLoading}>
          {watchedLocations.length > 0 ? (
            <div
              style={{
                display: "grid",
                gridColumnGap: "20px",
                gridRowGap: "20px",
                justifyItems: "stretch",
                gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
              }}
            >
              {watchedLocations.map((location) => {
                return (
                  <InventoryLocationPanel
                    location={location}
                    pushDetailsView={pushDetailsView}
                  />
                );
              })}
            </div>
          ) : (
            <Alert variant={AlertVariant.Primary} show>
              <p>
                {t("inventory-view:Please select your locations to proceed.")}
              </p>
            </Alert>
          )}
        </Loader>
      </div>
    </ResponsiveSection>
  );
};

InventoryViewDashboard.propTypes = {
  watchedLocations: PropTypes.array,
  allWatchedLocations: PropTypes.array,
  pushDetailsView: PropTypes.func,
  pushDashBoardView: PropTypes.func,
  fetchWatchedLocations: PropTypes.func,
  fetchAllWatchedLocations: PropTypes.func,
  isWatchedLocationsLoading: PropTypes.bool,
  isAllWatchedLocationsLoading: PropTypes.bool,
  saveWatchedLocations: PropTypes.func,
};

export default InventoryViewDashboard;
