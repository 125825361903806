/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dashboard } from "components/templates/Dashboard.template";
import SearchBarContainer from "../components/search/SurgicalToteTrackingSearchBarContainer";
import { SurgicalToteTrackingSavedSearchPanel } from "./components/SurgicalToteTracking.SavedSearchesPanel";
import SurgicalToteTrackingSearchFiltersContainer from "../components/search/SurgicalToteTrackingSearchFiltersContainer";
import { SurgicalToteTrackingCountsWidget } from "./components/SurgicalToteTrackingCountsWidget";
import SurgicalToteTrackingMyWatchedTotes from "./components/SurgicalToteTrackingMyWatchTotes.container";

import {
  useSetTitleOnMount,
  useSetDescriptionOnMount,
} from "components/hooks/useSetTitle";

import { MediaQueries } from "components/responsive";

export const SurgicalToteDashboard = (props) => {
  const {
    totesTotals,
    isTotesTotalsLoading,
    exceptionTypes,
    isExceptionTypesIsLoading,
    fetchTotesTotals,
    fetchExceptionTypes,
    searchEntities,
    setSearchFilter,
    resetSearchAndFilters,
  } = props;

  const { t } = useTranslation("surgical-tote-tracking");
  const [showFilters, setShowFilters] = useState(false);

  useSetTitleOnMount(t("surgical-tote-tracking:Healthcare Container Tracking"));
  useSetDescriptionOnMount(
    t(
      "surgical-tote-tracking:All Totes for your organization and their related exceptions",
    ),
  );

  useEffect(() => {
    fetchTotesTotals();
    fetchExceptionTypes();
    resetSearchAndFilters(true);
  }, [fetchTotesTotals, fetchExceptionTypes, resetSearchAndFilters]);

  return (
    <Dashboard
      SearchBarContainer={SearchBarContainer}
      FiltersContainer={SurgicalToteTrackingSearchFiltersContainer}
      showFilters={showFilters}
      toggleShowFilters={(newShowFilters) => setShowFilters(newShowFilters)}
    >
      <Dashboard.Tabs>
        <Dashboard.TabList>
          <Dashboard.Tab>
            {t("surgical-tote-tracking:Summary View")}
          </Dashboard.Tab>
          <Dashboard.Tab>
            {t("surgical-tote-tracking:My Saved Searches")}
          </Dashboard.Tab>
        </Dashboard.TabList>
        <Dashboard.TabPanel>
          <div
            css={{
              [MediaQueries.smallAndDown]: {
                marginBottom: "20px",
              },
            }}
          >
            <SurgicalToteTrackingCountsWidget
              totesTotals={totesTotals}
              isTotesTotalsLoading={isTotesTotalsLoading}
              exceptionTypes={exceptionTypes}
              isExceptionTypesIsLoading={isExceptionTypesIsLoading}
              searchEntities={searchEntities}
              setSearchFilter={setSearchFilter}
              resetSearchAndFilters={resetSearchAndFilters}
            />
          </div>
        </Dashboard.TabPanel>
        <Dashboard.TabPanel>
          <SurgicalToteTrackingSavedSearchPanel />
          <SurgicalToteTrackingMyWatchedTotes />
        </Dashboard.TabPanel>
      </Dashboard.Tabs>
    </Dashboard>
  );
};

SurgicalToteDashboard.propTypes = {
  totesTotals: PropTypes.object.isRequired,
  isTotesTotalsLoading: PropTypes.bool.isRequired,
  exceptionTypes: PropTypes.object.isRequired,
  isExceptionTypesIsLoading: PropTypes.bool.isRequired,
  fetchTotesTotals: PropTypes.func.isRequired,
  fetchExceptionTypes: PropTypes.func.isRequired,
  searchEntities: PropTypes.func,
  setSearchFilter: PropTypes.func,
  resetSearchAndFilters: PropTypes.func,
};

export default SurgicalToteDashboard;
