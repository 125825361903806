import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { customerApiUrl } from "api-url";

const fetchParts = async (
  locationId,
  searchText,
  pageNumber,
  pageSize,
  sortColumn,
  reverseSort,
) => {
  if (!locationId) {
    return { parts: [], meta: { totalCount: 0, totalPages: 0 } };
  }
  const url = customerApiUrl("critical-parts/location-inventory/" + locationId);

  try {
    const response = await axios.get(url, {
      params: {
        searchText: searchText,
        pageNumber: pageNumber,
        pageSize: pageSize,
        ...(sortColumn
          ? {
              sortColumn: sortColumn,
              sortDirection: reverseSort ? "desc" : "asc",
            }
          : {}),
      },
    });

    const data = response.data;

    if (
      !data.data ||
      Array.isArray(data.data.parts) ||
      data.data.length === 0
    ) {
      return { parts: [], meta: { totalCount: 0, totalPages: 0 } };
    }

    return data;
  } catch (error) {
    return { parts: [], meta: { totalCount: 0, totalPages: 0 } };
  }
};

export const useCriticalPartsListData = (
  locationId,
  searchText,
  pageNumber,
  pageSize,
  sortColumn,
  reverseSort,
) => {
  const {
    isFetching: isFetchingList,
    data: criticalPartsList = {
      parts: [],
      meta: { totalCount: 0, totalPages: 0 },
    },
    refetch,
  } = useQuery({
    queryKey: [
      locationId,
      searchText,
      pageNumber,
      pageSize,
      sortColumn,
      reverseSort,
      "Critical Parts List",
    ],
    enabled: true,
    refetchInterval: 30000,
    queryFn: () =>
      fetchParts(
        locationId,
        searchText,
        pageNumber,
        pageSize,
        sortColumn,
        reverseSort,
      ),
  });
  return { isFetchingList, criticalPartsList, refetch };
};
