/** @jsxImportSource @emotion/react */
import PropTypes, { object } from "prop-types";
import { useTranslation } from "react-i18next";
import { Text } from "components/atoms/Text.atom";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { FontSize } from "components/atoms/Text.atom";
import moment from "moment";
import {
  getDifferenceBetweenTimestamps,
  localizedDateFormatter,
  formatDuration,
  localizedTimeFormatter,
  getDateFormatFromUserPreferences,
} from "utils/date-time";
import Colors from "styles/colors";

export const useColumns = () => {
  const { t } = useTranslation("critical-parts");
  let columns = [
    {
      Header: t("critical-parts:Watch"),
      accessor: "watch",
      width: 50,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: true,
      Cell: (cellInfo) => {
        return (
          <WatchToggle
            data-qa="toggle-watch"
            key={cellInfo.row.original?.externalId}
            checked={false}
            onClick={(e) => {
              console.log("clicked");
            }}
            onChange={(newWatchValue) => {
              console.log("on change called");
            }}
            iconSize={FontSize.size24}
            color={Colors.nav.NAVY}
            checkedColor={Colors.highlight.YELLOW}
          />
        );
      },
    },
    {
      Header: t("critical-parts:Part"),
      accessor: "partName",
      width: 200,
      disableSortBy: true,
      Cell: PartCell,
    },
    {
      Header: t("critical-parts:Run Out By"),
      accessor: "runOutBy",
      id: "run_out_by",
      width: 200,
      Cell: RunOutByCell,
    },
    {
      Header: t("critical-parts:Must Ship By"),
      accessor: "mustShipBy",
      id: "must_ship_by",
      width: 200,
      Cell: MustShipByCell,
    },
    {
      Header: t("critical-parts:Last Count"),
      accessor: "lastCountDate",
      width: 200,
      disableSortBy: true,
      Cell: LastCountCell,
    },
    {
      Header: t("critical-parts:Days On Hand"),
      accessor: "daysOnHand",
      width: 200,
      disableSortBy: true,
      Cell: DaysOnHandCell,
    },
    {
      Header: t("critical-parts:Requirements"),
      accessor: "requirements",
      width: 200,
      disableSortBy: true,
      Cell: RequirementsCell,
    },
    {
      Header: t("critical-parts:Next Shipment"),
      accessor: "nextShipments",
      width: 200,
      disableSortBy: true,
      Cell: NextShipmentCell,
    },
    {
      Header: t("critical-parts:Notes/Comments"),
      accessor: "notesComments",
      id: "notesCommments",
      width: 200,
      disableSortBy: true,
      Cell: NotesCell,
    },
  ];

  return columns;
};

const SupplierInfo = (props) => {
  const { t } = useTranslation("critical-parts");

  if (props?.row?.original?.suppliers?.length === 0) {
    return <Text block>{t("critical-parts:No Supplier Info")}</Text>;
  }
  const supplierInfo = props?.row?.original?.suppliers?.[0];

  const transitTime = formatDuration(supplierInfo?.transitTime);
  return (
    <>
      <Text block bold>
        {supplierInfo?.name}
      </Text>
      <Text block>
        {supplierInfo?.city}, {supplierInfo?.state}
      </Text>
      <Text block>{supplierInfo?.phoneNumber}</Text>
      <Text block>
        {t("critical-parts:Transit time")}
        {": "}
        {transitTime}
      </Text>
    </>
  );
};

export const PartCell = (props) => {
  return (
    <div>
      <div data-qa="cell-parts">
        <Text block bold>
          {props.row?.original?.partName}
        </Text>
        <Text> {props.row?.original?.partNumber}</Text>

        <SupplierInfo {...props} />
      </div>
    </div>
  );
};

const RunOutByCell = (props) => {
  const { t } = useTranslation("critical-parts");
  if (props.value === null) {
    return (
      <div data-qa="cell-run-out-by">
        <Text block bold>
          {t("critical-parts:No Data")}
        </Text>
      </div>
    );
  }
  const date = moment(props.value);
  const formattedDate = localizedDateFormatter(date);
  const formattedTime = localizedTimeFormatter(date);
  const now = moment();
  const timeLeft = Math.round(
    getDifferenceBetweenTimestamps(date, now, "Hours"),
  );
  return (
    <div data-qa="cell-run-out-by">
      <Text block bold>
        {formattedDate}
      </Text>
      <Text block bold>
        {formattedTime}
      </Text>
      <Text
        block
        bold
        css={{
          color: timeLeft <= 1 ? Colors.highlight.RED : Colors.highlight.YELLOW,
        }}
      >
        {t("critical-parts:[[[count]]] Hour", { count: timeLeft })}
      </Text>
    </div>
  );
};

const MustShipByCell = (props) => {
  const { t } = useTranslation("critical-parts");
  if (props.value === null) {
    return (
      <div data-qa="cell-must-ship-by">
        <Text block bold>
          {t("critical-parts:No Data")}
        </Text>
      </div>
    );
  }
  const date = moment(props.value);
  const formattedDate = localizedDateFormatter(date);
  const formattedTime = localizedTimeFormatter(date);
  const now = moment();
  const timeLeft = Math.round(
    getDifferenceBetweenTimestamps(date, now, "Hours"),
  );
  return (
    <div data-qa="cell-must-ship-by">
      <Text block bold>
        {formattedDate}
      </Text>
      <Text block bold>
        {formattedTime}
      </Text>
      <Text
        block
        bold
        css={{
          color: timeLeft <= 1 ? Colors.highlight.RED : Colors.highlight.YELLOW,
        }}
      >
        {t("critical-parts:[[[count]]] Hour", { count: timeLeft })}
      </Text>
    </div>
  );
};

export const LastCountCell = (props) => {
  const { lastCountDate, lastCount } = props?.row?.original;

  const dateFormat = getDateFormatFromUserPreferences();
  const formattedLastUpdatedCount = moment(lastCountDate).format(
    `${dateFormat}`,
  );
  return (
    <div data-qa="cell-last-count">
      <Text block bold>
        {formattedLastUpdatedCount}
      </Text>
      <Text block>{lastCount}</Text>
    </div>
  );
};

export const DaysOnHandCell = (props) => {
  return (
    <div data-qa="cell-days-on-hand">
      <Text block bold>
        {props?.row?.original?.daysOnHandMinimum}
      </Text>
      <Text block>
        {props?.row?.original?.currentAmount?.amount}{" "}
        {props?.row?.original?.currentAmount?.unit}
      </Text>
    </div>
  );
};

export const RequirementsCell = (props) => {
  return (
    <div data-qa="cell-requirements">
      <Text block bold>
        {props?.row?.original?.requiredAmount}
      </Text>
    </div>
  );
};

export const NextShipmentCell = (props) => {
  const nextShipment = props?.value?.[0];
  return (
    <div data-qa="cell-next-shipment">
      <Text block>{nextShipment?.eta}</Text>
      <Text block>{nextShipment?.fvId}</Text>
      <Text block>{nextShipment?.shipmentId}</Text>
      <Text block>{nextShipment?.scac}</Text>
      <Text block>{nextShipment?.mode}</Text>
      <Text block>{nextShipment?.daysOnHandReceiving}</Text>
      <Text block>{nextShipment?.quantityReceiving}</Text>
    </div>
  );
};

export const NotesCell = (props) => {
  const { t } = useTranslation("critical-parts");
  const { systemComment, userComment } = props?.row?.original;
  return (
    <div data-qa="cell-notes">
      {systemComment ? (
        <Text block>
          {t("critical-parts:System Comment")}
          {": "}
          {systemComment?.comment}
        </Text>
      ) : null}
      {userComment ? (
        <>
          <Text block>
            {t("critical-parts:User Comment")}
            {": "}
            {userComment?.comment}
          </Text>
          <Text block>
            {t("critical-parts:Email")}
            {": "}
            {userComment?.email}
          </Text>
        </>
      ) : null}
    </div>
  );
};

PartCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      partName: PropTypes.string,
      partNumber: PropTypes.string,
      suppliers: PropTypes.array,
    }),
  }),
};

RunOutByCell.propTypes = {
  value: PropTypes.string,
};

MustShipByCell.propTypes = {
  value: PropTypes.string,
};

LastCountCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      lastCountDate: PropTypes.string,
      lastCount: PropTypes.number,
    }),
  }),
};

DaysOnHandCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      daysOnHandMinimum: PropTypes.number,

      currentAmount: PropTypes.shape({
        amount: PropTypes.number,
        unit: PropTypes.string,
      }),
      minAmount: PropTypes.number,
    }),
  }),
};

RequirementsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      requiredAmount: PropTypes.number,
      partsPerHour: PropTypes.number,
    }),
  }),
};

NextShipmentCell.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      eta: PropTypes.string,
      fvId: PropTypes.string,
      shipmentId: PropTypes.string,
      scac: PropTypes.string,
      mode: PropTypes.string,
      daysOnHandReceiving: PropTypes.number,
      quantityReceiving: PropTypes.number,
    }),
  ),
};

NotesCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      systemComment: PropTypes.shape({
        comment: PropTypes.string,
      }),
      userComment: PropTypes.shape({
        comment: PropTypes.string,
        email: PropTypes.string,
      }),
    }),
  }),
  value: PropTypes.shape({
    systemComment: PropTypes.shape({
      comment: PropTypes.string,
    }),
    userComment: PropTypes.shape({
      comment: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
};

SupplierInfo.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      suppliers: PropTypes.array,
    }),
  }),
};
