/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import Loader from "react-loader";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Separator } from "components/atoms/Separator.atom";
import { FontSize, Text } from "components/atoms/Text.atom";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { DownloadDataButton } from "components/molecules/DownloadDataButton.molecule";
import { MediaQueries } from "components/responsive";
import {
  BaseTable,
  BaseTablePropTypes,
} from "components/organisms/base-table/BaseTable.organism";
import { SearchButtonContainer } from "components/saved-search/ButtonSeparator";
import { ResetSearchButton } from "components/saved-search/ResetSearchButton";
import { SaveThisSearchButton } from "components/saved-search/SaveThisSearchButton";
import ExportModal from "modules/exports/ExportModal";
import { ExportAlert } from "modules/exports/ExportAlert";
import Colors from "styles/colors";
import { convertCountToLocaleString } from "utils/html-utils";

export const Search = ({
  isLoading,
  emptyDataOnLoad = true,
  searchResults,
  showError = false,
  showErrorMessage = null,
  hideSearchResults = false,
  hideSearchResultsMessage = null,
  totalCount,
  totalCountIsLoading = isLoading,
  totalCountTitle,
  savedSearch,
  loadSavedSearch,
  SavedSearchModalContainer,
  resetSearch,
  showFilters,
  toggleShowFilters,
  SearchBarContainer,
  FiltersContainer,
  exportProps,
  tableProps,
  hideSavedSearch = false,
  hideResetSearch = false,
  disableDownloadData = false,
  productSpecificSearchBtn = [],
  searchWrapperStyle = {},
  tableWrapperStyle = {},
}) => {
  const { t } = useTranslation("components");
  const [showSaveSearchModal, setShowSaveSearchModal] = useState(false);

  useEffect(() => {
    toggleShowFilters(true);

    // If a saved search was previously selected, automatically preload its query values
    if (savedSearch) {
      loadSavedSearch(savedSearch);
    }
  }, [toggleShowFilters, savedSearch, loadSavedSearch]);

  // Ensure that the displayed count of results does not exceed the page size.
  // If the list is very large, there is a chance the page could freeze.
  let data = searchResults?.slice(0, tableProps?.pageSize ?? 20);
  let showResultCountPageSizeMismatchError =
    searchResults?.length > data?.length;

  // Hide results by the prop or if results are loading.
  if ((isLoading && emptyDataOnLoad) || hideSearchResults) {
    data = [];
  }

  const isExportEnabled = _.isObject(exportProps) && !_.isEmpty(exportProps);

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {isExportEnabled ? <Loader loaded={!exportProps.isExporting} /> : null}
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          padding: "1em 1em 0 1em",
          ...searchWrapperStyle,
        }}
      >
        {SearchBarContainer ? (
          <SearchBarContainer
            isShowingFilters={showFilters}
            toggleShowFilters={() => toggleShowFilters(!showFilters)}
          />
        ) : null}
        {FiltersContainer ? (
          <FiltersContainer
            show={showFilters}
            backgroundColor={Colors.background.GRAY}
            sectionStyle={{ paddingRight: "8em" }}
          />
        ) : null}
      </div>
      {showError && showErrorMessage ? (
        <Alert
          variant={AlertVariant.Danger}
          show
          style={{ margin: "0 1em 1em 1em" }}
        >
          {showErrorMessage}
        </Alert>
      ) : null}
      {showResultCountPageSizeMismatchError ? (
        <Alert
          variant={AlertVariant.Warning}
          show
          style={{ margin: "0 1em 1em 1em" }}
        >
          {t(
            "components:You may be missing results from your search. Please report to FreightVerify.",
          )}
        </Alert>
      ) : null}
      {hideSearchResults && hideSearchResultsMessage ? (
        <Alert
          variant={AlertVariant.Warning}
          show
          style={{ margin: "0 1em 1em 1em" }}
        >
          {hideSearchResultsMessage}
        </Alert>
      ) : null}

      {!hideSearchResults ? (
        <div
          css={{
            padding: "0 1em 1em 1em",
            ...tableWrapperStyle,
          }}
        >
          {isExportEnabled ? (
            <ExportAlert
              exportFailed={exportProps.exportFailed}
              onDismiss={exportProps.clearExportErrors}
              className="my-2"
            />
          ) : null}
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              [MediaQueries.smallAndUp]: {
                flexDirection: "row",
                width: "unset",
              },
            }}
          >
            <div
              style={{
                flex: "1 0",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Text
                size={FontSize.size16}
                bold
                css={{
                  position: "relative",
                  ".loadedContent": { display: "inline" },
                }}
              >
                <Loader loaded={!totalCountIsLoading} scale={0.35} left="0.5em">
                  {convertCountToLocaleString(totalCount)}
                </Loader>
              </Text>
              <Text
                size={FontSize.size16}
                css={{
                  marginLeft: totalCountIsLoading ? "1.2em" : 4,
                  whiteSpace: "nowrap",
                }}
              >
                {totalCountTitle}
              </Text>
            </div>

            <SearchButtonContainer>
              {productSpecificSearchBtn.map((searchBtn) => {
                return (
                  <Fragment key={`${searchBtn}`}>
                    {searchBtn}
                    <Separator style={{ height: "1em" }} />
                  </Fragment>
                );
              })}

              {!hideResetSearch ? (
                <ResetSearchButton onClick={() => resetSearch()} />
              ) : null}

              {!hideSavedSearch ? (
                <Fragment>
                  <SaveThisSearchButton
                    onClick={() => setShowSaveSearchModal(true)}
                  />
                  <Separator style={{ height: "1em" }} />
                </Fragment>
              ) : null}

              {isExportEnabled ? (
                <DownloadDataButton
                  onClick={() => exportProps.exportEntities()}
                  isDisabled={disableDownloadData ? true : data.length === 0}
                  isExporting={exportProps.isExporting}
                  data-qa="button-search-results-export"
                  style={{
                    display:
                      exportProps.exportEntities === null ? "none" : "inline",
                  }}
                />
              ) : null}
            </SearchButtonContainer>
          </div>
          {isExportEnabled ? (
            <ExportModal
              exportIdentifier={exportProps.exportIdentifier}
              exportName={exportProps.exportName}
              resetExport={exportProps.resetExport}
            />
          ) : null}
          <BaseTable
            // Apply default props for the table when using the Search template,
            // then apply the props passed in.
            fixHeaderToTop={true}
            height="90vh"
            {...tableProps}
            // Override the data prop to use the limited results.
            data={data}
          />
        </div>
      ) : null}
      {!hideSavedSearch && (
        <SavedSearchModalContainer
          show={showSaveSearchModal}
          hide={() => {
            setShowSaveSearchModal(false);
          }}
        />
      )}
    </div>
  );
};

const ExportPropTypes = {
  exportEntities: PropTypes.func,
  exportIdentifier: PropTypes.string,
  exportName: PropTypes.string,
  isExporting: PropTypes.bool,
  exportFailed: PropTypes.bool,
  clearExportErrors: PropTypes.func,
  resetExport: PropTypes.func,
};

Search.propTypes = {
  isLoading: PropTypes.bool,
  emptyDataOnLoad: PropTypes.bool,
  searchResults: PropTypes.array,
  showError: PropTypes.bool,
  showErrorMessage: PropTypes.string,
  hideSearchResults: PropTypes.bool,
  hideSearchResultsMessage: PropTypes.string,
  totalCount: PropTypes.number,
  totalCountIsLoading: PropTypes.bool,
  totalCountTitle: PropTypes.string,
  savedSearch: PropTypes.object,
  loadSavedSearch: PropTypes.func,
  SavedSearchModalContainer: PropTypes.elementType,
  resetSearch: PropTypes.func,
  showFilters: PropTypes.bool,
  toggleShowFilters: PropTypes.func,
  SearchBarContainer: PropTypes.elementType,
  FiltersContainer: PropTypes.elementType,
  exportProps: PropTypes.exact(ExportPropTypes),
  tableProps: PropTypes.exact(BaseTablePropTypes),
  showOnlyReset: PropTypes.bool,
  hideSavedSearch: PropTypes.bool,
  hideResetSearch: PropTypes.bool,
  disableDownloadData: PropTypes.bool,
  productSpecificSearchBtn: PropTypes.array,
  searchWrapperStyle: PropTypes.object,
  tableWrapperStyle: PropTypes.object,
};
