import moment from "moment";

import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";

import {
  INVENTORY_VIEW_DETAILS_FILTERS,
  INVENTORY_VIEW_DETAILS_SEARCH_CATEGORIES,
} from "../details/search/InvertoryView.Details.Search.Options";

const STORE_MOUNT_POINT = "inventoryViewDetailsSearch";

const INVENTORY_VIEW_DETAILS_URL = apiUrl(`/entity-inventory/search`);

const UPDATE_CURRENT_LOCATION = `${STORE_MOUNT_POINT}/UPDATE_CURRENT_LOCATION`;

/**
 * Transform the returned data into a format easily understadable by our table
 */
const transformEntities = (payload) => {
  payload.data = payload.data.map((item) => {
    return {
      externalId: item.externalId,
      internal_id: item.internal_id,
      productType: item.productType || "",
      orderType: item.orderType || "",
      productionDate: item.productionDate || "",
      gateRelease: item.gateRelease || "",
      isShippable: item?.isShippable ?? false,
      currentLocation: item?.currentLocation || {},
      forecastedArrival: item.forecastedArrival,
      initialEta: item.initialEta,
      destination: item.destination,
      arrivalTimestamp: item.arrivalTimestamp,
      departureTimestamp: item.departureTimestamp,
    };
  });

  return payload;
};

const fetchSearch = (queryString = null, solutionId, duck, dispatch, state) => {
  const url = `${INVENTORY_VIEW_DETAILS_URL}?inventoryLocationId=${state[STORE_MOUNT_POINT].currentLocation}&${queryString}`;

  const config = {
    headers: {
      "x-time-zone": moment.tz.guess(),
      Accept: "application/json;",
    },
  };

  // Fetch the search
  dispatch(duck.fetch(url, config, transformEntities));
};

// Selectors
const setCurrentLocation = (id) => {
  return { type: UPDATE_CURRENT_LOCATION, payload: id };
};

// Selectors

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  INVENTORY_VIEW_DETAILS_SEARCH_CATEGORIES,
  INVENTORY_VIEW_DETAILS_FILTERS,
  fetchSearch,
  [],
);

SearchBarState.selectors = {
  ...SearchBarState.selectors,
};

SearchBarState.actionCreators = {
  ...SearchBarState.actionCreators,
  setCurrentLocation,
};

export default SearchBarState;
